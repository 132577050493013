import React, { Component } from "react";

class FooterUE extends Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-md-center margin-t-3 margin-b-1">
          <div className="col-md-10">
            <p className="text-center">
              <img
                src="https://cdn3.bluebirdcms.net/uploaded/b0ad9158-283e-4519-8848-ee48c97382b1/images/logotypy_ue_650.png"
                className="img-fluid"
                alt="Projekt „Wdrożenie modelu rozwoju kompetencji przedstawicieli KL” realizowany jest przez Konfederację Lewiatan, zgodnie z umową UDA- POWR.02.20.00-00-0008/18-00, współfinansowany przez Unię Europejską w ramach Europejskiego Funduszu Społecznego."
              ></img>
            </p>
            <p className="small text-center">
              Projekt „Wdrożenie modelu rozwoju kompetencji przedstawicieli KL” realizowany jest przez Konfederację Lewiatan, zgodnie z umową UDA-
              POWR.02.20.00-00-0008/18-00, współfinansowany przez Unię Europejską w ramach Europejskiego Funduszu Społecznego.
            </p>
            <p className="small text-center">
              <a href="/o-projekcie">więcej informacji o projekcie...</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterUE;
