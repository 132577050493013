import React, { Component } from "react";

class TopNavUE extends Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-md-center margin-b-3 margin-t-1">
          <div className="col-12">
            <p className="text-center xs-text-center">
              <img
                src="https://cdn3.bluebirdcms.net/uploaded/b0ad9158-283e-4519-8848-ee48c97382b1/images/logotypy_ue_450.png"
                className="img-fluid"
                alt="Projekt „Wdrożenie modelu rozwoju kompetencji przedstawicieli KL” realizowany jest przez Konfederację Lewiatan, zgodnie z umową UDA- POWR.02.20.00-00-0008/18-00, współfinansowany przez Unię Europejską w ramach Europejskiego Funduszu Społecznego."
              ></img>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TopNavUE;
